import React, {useEffect, useState} from 'react';
import { Alert, Form, Button } from 'react-bootstrap'

function LoginForm(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePass = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    setShowAlert(false);
    e.preventDefault();
    console.log(email + ":" + password);
    try {
      await props.login(email, password);
    } catch(e) {
      setShowAlert(true);
    }
  };

  return (
    <div className="App">
      {showAlert &&
        <Alert variant="danger">ログインできません</Alert> 
      }
      <header className="App-header">
        <img src="/logo.png" className="logo" alt="logo" />
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label></Form.Label>
            <Form.Control type="email" value={email} onChange={handleEmail} placeholder="メールアドレス" />
          </Form.Group>
          <Form.Group controlId="formBasicPass">
            <Form.Label></Form.Label>
            <Form.Control type="password" value={password} onChange={handlePass} placeholder="パスワード" />
          </Form.Group>
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            ログイン
          </Button>
        </Form>
      </header>
    </div>
  );
}

export{LoginForm};
