import React, {useEffect, useState} from 'react';
import Amplify, { Auth, API } from 'aws-amplify';
import { awsconfig } from './aws-config';
import './App.css';

import { LoginForm } from './Form';
import { Dashboard } from './containers/Dashboard';

export const useDidMount = (func) => useEffect(() => { func() }, []);

Amplify.configure(awsconfig);

function App() {
  const [user, setUser] = useState(null);

  const onload = async () => {
    try {
      let currentUser = await Auth.currentAuthenticatedUser();
      if (currentUser) {
        setUser(currentUser);
      }
    } catch(e) {
      console.log('Please login to proceed');
    }
  };

  useDidMount(() => {
    onload();
  });

  const login = async (email, password) => {
    try {
      const newUser = await Auth.signIn(email, password);
      setUser(newUser);
    } catch(e) {
      throw e;
    }
  };

  if (!user) {
    return <LoginForm login={login} />
  } else {
    return <Dashboard Auth={Auth} API={API} />
  }
}

export default App;
