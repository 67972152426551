import React, {useEffect, useState} from 'react';
import { Button, Card } from 'react-bootstrap';

function Test(props) {
  return (<div style={{width:'400px',marginLeft:'auto',marginRight:'auto',marginTop:'40px'}}>
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>通話テスト</Card.Title>
        <Card.Text>
          ブラウザのマイクやスピーカーをテストします。
        </Card.Text>
        <Button variant="primary" onClick={() => {
          if (props.online) {
            props.Device.connect({
              mode: 'test'
            });
          } else {
            alert('左側メニューのスイッチをオンにして「オンライン」にしてください。');
          }
        }}>受話テスト</Button>
      </Card.Body>
    </Card>
  </div>);
}

export{Test};
