import React, {useEffect, useState} from 'react';
import { Button } from 'react-bootstrap';

function Sidebar(props) {
  const [menuTitle, setMenuTitle] = useState('通話テスト');
  const [settingsTitle, setSettingsTitle] = useState('設定');
  return (
  <nav id="sidebar">
    <p style={{cursor:'pointer'}} onClick={
      () => {
        props.setShowTest(!props.showTest);
        const newMenu = menuTitle == '通話テスト' ? '履歴に戻る' : '通話テスト';
        setMenuTitle(newMenu);
      }
    }>{menuTitle}</p>
    <label className="switch">
      <input type="checkbox" checked={props.online} onChange={() => {
        let newState = props.online ? false : true;
        props.setOnline(newState)}
      } />
      <span className="slider round"></span> <span className="online-label">{props.online ? 'オンライン' : 'オフライン'}</span>
    </label>
    <p style={{cursor:'pointer'}} onClick={() => {
      const title = settingsTitle == '設定' ? '履歴に戻る' : '設定';
      props.setShowSettings(!props.showSettings);
      setSettingsTitle(title);
    }}>{settingsTitle}</p>
    <Button style={{marginLeft:'34px'}} color="primary" onClick={() => {
      props.callToSuppot();
    }}>サポートに電話</Button>
  </nav>

  );
}

export{Sidebar};
