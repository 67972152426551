import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faPhoneVolume, faPhoneSlash } from '@fortawesome/free-solid-svg-icons'
import { Figure } from 'react-bootstrap';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

function Phone(props) {
  const leftButton = {
    fontSize:'48px',
    color:'#f4f4f4',
  };
  const rightButton = {
    fontSize:'48px',
    color:'#f2f2f2'
  };

  let from;
  if (props.connection) {
    if (props.connection.customParameters) {
      from = props.connection.customParameters.get('call_from');
      if (from.match(/^client/)) {
        from = 'ブラウザフォン';
      } else {
        try {
          const phoneNumber = parsePhoneNumberFromString(from.replace(/^%2B/, '+'), 'JP');
          from = phoneNumber.formatNational();
        } catch(e){}
      }
    }
  }

  if (props.show === false) {return <div />}
  if (props.outbound) {
    return (
    <div className="Phone-container">
      <h2>発信中</h2>
      <img src="/logo_h.jpg" />
      <h3>{from}</h3>
      <div style={{display:'flex',marginTop:'40px'}}>
        <button className="Circle-button-red" onClick={props.disconnect}>
          <FontAwesomeIcon style={rightButton} icon={faPhoneSlash} />
        </button>
      </div>
    </div>
    );
  }
  if (!props.connection) {return <div />}
  return (
    <div className="Phone-container">
      <h2>着信中</h2>
      <img src="/logo_h.jpg" />
      <h3>{from}</h3>
      <div style={{display:'flex',marginTop:'40px'}}>
        <div style={{flex:0.5,textAlign:'center'}}>
          <button className="Circle-button-green" onClick={props.accept}>
          <FontAwesomeIcon style={leftButton} icon={faPhoneVolume} />
          </button>
        </div>
        <div style={{flex:0.5,textAlign:'center'}}>
          <button className="Circle-button-red" onClick={props.disconnect}>
            <FontAwesomeIcon style={rightButton} icon={faPhoneSlash} />
          </button>
        </div>
      </div>
    </div>
  );
}

export{Phone};
