import React, {useEffect, useState} from 'react';
import { ListGroup } from 'react-bootstrap';

import { ListItem } from './listItem';

let myPage = 1;
let myLkey = null;

function List(props) {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(null);
  const [limit , setLimit] = useState(20);
  const [LastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    myPage = page;
    if (page != null) {
      getList();
    }
  }, [page]);

  useEffect(() => {
    onLoad();
    const newTimer = setInterval(() => {
      if (myPage == null || myPage == 1) {
        getList();
      }
    }, 5000);
    //props.EE.addListener('ws:call', getList);
    props.EE.addListener('call_finished', getList);
    return () => {
      clearInterval(newTimer);
      props.EE.removeListener('call_finished', getList);
      //props.EE.removeListener('ws:call', getList);
    };
  }, []);

  useEffect(() => {
    console.log('lkey', LastEvaluatedKey);
    myLkey = LastEvaluatedKey;
  }, [LastEvaluatedKey]);

  const onLoad = () => {
    setPage(1);
  };

  const decrementPage = () => {
    setPage(Math.max(1, parseInt(page) - 1));
  };

  const incrementPage = () => {
    setPage(parseInt(page) + 1);
  };

  const getList = async () => {
    const session = await props.Auth.currentSession();
    let url;
    if (myPage == 1) {
      url = `/list?limit=${limit}&page=${myPage}`;
    } else {
      url = myLkey ? `/list?limit=${limit}&page=${myPage}&LastEvaluatedKey=${encodeURIComponent(JSON.stringify(myLkey))}` : `/list?limit=${limit}&page=${myPage}`;
    }
    const listData = await props.API.get('List', url, {
      headers: {Authorization: session.idToken.jwtToken}});
    setList(listData.Items);
    if (listData.LastEvaluatedKey != null) { 
      setLastEvaluatedKey(listData.LastEvaluatedKey);
    }
  };
  return(<div>
    <ListGroup style={{width:'80%',marginLeft:'auto',marginRight:'auto',marginTop:'40px',marginBottom:'40px'}}>
      <ListItem />
    {list.map((history, idx) => {
      return <ListItem history={history} key={idx} {...props} />;
    })}
    {LastEvaluatedKey != null &&
      <div className="pagenation">
        <div><span onClick={decrementPage}>前へ</span> | <span onClick={incrementPage}>次へ</span></div>
      </div>
    }
    </ListGroup>
  </div>);
}

export{List};

