import React, {useEffect, useState} from 'react';
import { Config } from '../../config';
import { Button, ListGroup } from 'react-bootstrap';
import moment from 'moment';
import { FaStopwatch, FaPhoneAlt, FaRegPlayCircle } from 'react-icons/fa';
import { AiOutlineReload } from 'react-icons/ai';
import { MdDateRange } from 'react-icons/md';
import axios from 'axios';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

function ListItem(props) {
  const [url, setUrl] = useState(null);
  const [playClass, setPlayClass] = useState('reload-audio');

  const generateTmpUrl = async () => {
    setPlayClass('reload-audio rotate');
    const session = await props.Auth.currentSession();
    const data = JSON.parse(props.history.data);
    const r = await props.API.get('TmpUrl', `/tmp_url/${data.call_sid}`, {
      headers: {Authorization: session.idToken.jwtToken}});
    if (r.url) {
      setUrl(r.url);
      setPlayClass('reload-audio');
    }
  };

  const parseDuration = (duration) => {
    let hour, min, sec;
    if (parseInt(duration) < 60) {
      return `${duration}秒`;
    } else if (duration < 3600) {
      min = parseInt(parseInt(duration) / 60);
      sec = parseInt(parseInt(duration) % 60);
      return `${min}分${sec}秒`;
    } else {
      hour = parseInt(parseInt(duration) / 3600);
      return `${hour}時間${parseDuration(parseInt(duration) - (hour * 3600))}`;
    }
  };

  if (props.history) {
    let phoneNumber = '';
console.log(props.history);
    const notAvailable = [
      '+17378742833',
      '7378742833',
      '2562533',
      '+12562533',
      '8656696',
      '+18656696',
      '266696687',
      '+1266696687',
      '86282452253',
      '+186282452253',
      '464',
      '+1464'
    ];
    const data = JSON.parse(props.history.data);
    try {
      if (data.phone_number && notAvailable.indexOf(data.phone_number) >= 0) {
        phoneNumber = '非通知・利用不可';
      } else if (data.phone_number && data.phone_number.match(/^client/)) {
        phoneNumber = '無料通話';
      } else if (data.phone_number && data.phone_number.match(/^\+/)) {
        phoneNumber = parsePhoneNumberFromString(data.phone_number, 'JP').formatNational();
      } else if (data.to) {
        phoneNumber = `${parsePhoneNumberFromString(data.to, 'JP').formatNational()}(発信)`;
      } else {
        phoneNumber = data.phone_number;
      }
    } catch(e) {
      phoneNumber = '';
    }
    if (!phoneNumber) { return null; }
    return <ListGroup.Item style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
      <div style={{flex:2}}>{moment(props.history.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
      {phoneNumber == '無料通話' &&
        <div style={{flex:3}}><Button variant="warning" size="sm" onClick={() => {
        }}>{phoneNumber}</Button></div>
      }
      {phoneNumber == '非通知・利用不可' &&
        <div style={{flex:3}}><Button variant="danger" size="sm" onClick={() => {
        }}>{phoneNumber}</Button></div>
      }
      {phoneNumber.match(/発信/) &&
        <div style={{flex:3}}><Button variant="info" size="sm" onClick={() => {
        }}>{phoneNumber}</Button></div>
      }
      {!phoneNumber.match(/発信/) && phoneNumber.match(/^[\d]/) &&
        <div style={{flex:3}}><Button variant="primary" size="sm" onClick={() => {
          props.EE.emit('outboundCall', {to: data.phone_number});
        }}>{phoneNumber}</Button></div>
      }
      {url != null &&
        <div style={{flex:4}}>
          <AiOutlineReload className={playClass} onClick={generateTmpUrl}/>
          <audio className="audio-control" controls="controls" src={url}></audio>
        </div>
      }
      {url == null &&
        <div style={{flex:4}}>
          {data.bucket &&
            <FaRegPlayCircle style={{cursor:'pointer'}} className={playClass} onClick={generateTmpUrl} />
          }
        </div>
      }
      <div style={{flex:1}}>{parseDuration(data.duration)}</div>
    </ListGroup.Item>
  } else {
    return <ListGroup.Item style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
      <div style={{flex:2}}><MdDateRange /> 日時</div>
      <div style={{flex:3}}><FaPhoneAlt /> 電話番号</div>
      <div style={{flex:4}}>再生</div>
      <div style={{flex:1}}><FaStopwatch /> 通話時間</div>
    </ListGroup.Item>
  }
}

export{ListItem};
