import React, {useEffect, useState} from 'react';
import { Alert, Button, Form, ListGroup } from 'react-bootstrap';

const defaultPrefs = {
  monday: {start_hour: '12', start_min: '00', end_hour: '23', end_min: '30'},
  tuesday: {start_hour: '12', start_min: '00', end_hour: '23', end_min: '30'},
  wednesday: {start_hour: '12', start_min: '00', end_hour: '23', end_min: '30'},
  thursday: {start_hour: '12', start_min: '00', end_hour: '23', end_min: '30'},
  friday: {start_hour: '12', start_min: '00', end_hour: '23', end_min: '30'},
  saturday: {start_hour: '12', start_min: '00', end_hour: '23', end_min: '30'},
  sunday: {start_hour: '12', start_min: '00', end_hour: '23', end_min: '30'},
};

const dateNames = {
  monday: '月曜日',
  tuesday: '火曜日',
  wednesday: '水曜日',
  thursday: '木曜日',
  friday: '金曜日',
  saturday: '土曜日',
  sunday: '日曜日',
};

function Settings(props) {
  const [prefs, setPrefs] = useState(null);
  const [buttonActive, setButtonActive] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const onload = async () => {
    const session = await props.Auth.currentSession();
    try {
      const data = await props.API.get('Preferences', '/preferences', {
        headers: {Authorization: session.idToken.jwtToken}})
      console.log('prefs', data);
      if (!data) {
        data = defaultPrefs;
      }
      setPrefs(data);
    } catch(e) {
      setPrefs(defaultPrefs);
    }
  };

  useEffect(() => {
    onload();
  }, []);

  const formatStr = (n) => {
    const len = (String(10).length - String(n).length) + 1;
    return len > 0 ? new Array(len).join('0') + n : n;
  };

  const updatePrefs = (day, key, value) => {
    let newPrefs = prefs;
    newPrefs[day][key] = value;
    setPrefs(newPrefs);
  };

  const saveData = async () => {
    setButtonActive(false);
    setShowAlert(false);
    setShowMessage(false);
    const url = '/preferences';
    const session = await props.Auth.currentSession();
    try {
      await props.API.post('Preferences', url, {
        headers: {Authorization: session.idToken.jwtToken}, body: prefs})
      setShowMessage(true);
      setButtonActive(true);
    } catch (e) {
      setShowAlert(true);
      setButtonActive(true);
    }
  };

  if (prefs == null) { return <div>loading...</div>; }

  return <div>
    {showMessage &&
      <Alert variant="primary">
        データを保存しました
      </Alert>
    }
    {showAlert &&
      <Alert variant="danger">
        データを保存できませんでした
      </Alert>
    }
    <ListGroup style={{width:'60%',marginLeft:'auto',marginRight:'auto',marginTop:'40px',marginBottom:'40px'}}>
      <ListGroup.Item style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
      <div style={{flex:2}}>曜日</div>
      <div style={{flex:4}}>開始時刻</div>
      <div style={{flex:4}}>終了時刻</div>
      </ListGroup.Item>
      {Object.keys(dateNames).map((day) => {
        return <ListGroup.Item key={day} style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
        <div style={{flex:2}}>{dateNames[day]}</div>
        <div style={{flex:2}}>
          <div className="timeSelect">
            <Form.Control as="select" defaultValue={prefs[day]['start_hour']} onChange={(e) => {
              updatePrefs(day, 'start_hour', e.target.value);
            }}>
              {[...Array(24).keys()].map((n) => {
                return <option key={n} value={formatStr(n)}>{formatStr(n)}時</option>
              })}
            </Form.Control>
          </div>
        </div>
        <div style={{flex:2}}>
          <div className="timeSelect">
            <Form.Control as="select" defaultValue={prefs[day]['start_min']} onChange={(e) => {
              updatePrefs(day, 'start_min', e.target.value);
            }}>
              {[...Array(60).keys()].map((n) => {
                return <option key={n} value={formatStr(n)}>{formatStr(n)}分</option>
              })}
            </Form.Control>
          </div>
        </div>
        <div style={{flex:2}}>
          <div className="timeSelect">
            <Form.Control as="select" defaultValue={prefs[day]['end_hour']} onChange={(e) => {
              updatePrefs(day, 'end_hour', e.target.value);
            }}>
              {[...Array(24).keys()].map((n) => {
                return <option key={n} value={formatStr(n)}>{formatStr(n)}時</option>
              })}
            </Form.Control>
          </div>
        </div>
        <div style={{flex:2}}>
          <div className="timeSelect">
            <Form.Control as="select" defaultValue={prefs[day]['end_min']} onChange={(e) => {
              updatePrefs(day, 'end_min', e.target.value);
            }}>
              {[...Array(60).keys()].map((n) => {
                return <option key={n} value={formatStr(n)}>{formatStr(n)}分</option>
              })}
            </Form.Control>
          </div>
        </div>
        </ListGroup.Item>
      })}
      <div style={{padding: '20px'}}>
        <Button color="primary" onClick={saveData} active={buttonActive}>保存</Button>
      </div>
    </ListGroup>
  </div>;
}

export{Settings};
