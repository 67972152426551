const endPoint = 'https://iireoj52if.execute-api.ap-northeast-1.amazonaws.com/v1';
const awsconfig = {
  Auth: {
    identityPoolId: 'ap-northeast-1:1b1c205f-e1b4-4c74-b96d-11da8ab23a0d',
    region: 'ap-northeast-1',
    identityPoolRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_CKFyXZvls',
    userPoolWebClientId: '5cp600mobrdhr7itceeisspptf',
    mandatorySignIn: false,
  },
  API: {
    endpoints: [
      {
        name: 'CapabilityToken',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'TmpUrl',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'List',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'WebsocketToken',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'Preferences',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
    ]
  }
};

export{awsconfig};
